.panels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-family: IBM Plex Mono, monospace;
  font-weight: 500;
  background-color: var(--background-dark);
}

.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  transition: all 0.5s ease-in-out;
  font-size: 0.8rem;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.panel.open {
  flex: 100 0 auto;
  font-size: 20px;
  height: 100%;
  max-height: 85vh;
  display: flex;
}

.panel.closed {
  cursor: pointer;
  height: 5vh;
}

.panel.closed > div {
  transform: translateX(100%);
  transition: all 0.5s;
  height: 1rem;
}

.panel > * {
  transition: transform 0.5s;
  min-width: 100%;
}
.panel > *:first-child {
  transform: translateX(50%);
}
.panel.open > *:first-child {
  transform: translateX(-100%);
}

.panel > *:last-child {
  transform: translateX(100%);
}
.panel.open > *:last-child {
  transform: translateX(-50%);
}

.panel h1 {
  text-align: center;
  font-size: 1.5em;
}

.panel-heading-about span {
  color: var(--yellow);
  padding: 0.25rem;
  transition: all 50ms linear;
}

.panel-heading-projects span {
  color: var(--green);
  padding: 0.25rem;
  transition: all 50ms linear;
}

.panel-heading-skills span {
  color: var(--blue);
  padding: 0.25rem;
  transition: all 50ms linear;
}

.panel-heading-contact span {
  color: var(--pink);
  padding: 0.25rem;
  transition: all 50ms linear;
}

.panel-heading-about span:hover {
  background-color: var(--yellow);
  color: var(--background-dark);
}

.panel-heading-projects span:hover {
  background-color: var(--green);
  color: var(--background-dark);
}

.panel-heading-skills span:hover {
  background-color: var(--blue);
  color: var(--background-dark);
}

.panel-heading-contact span:hover {
  background-color: var(--pink);
  color: var(--background-dark);
}

.about {
  color: var(--yellow);
  transition: color 100ms;
}

.projects {
  color: var(--white);
  transition: color 100ms;
}

.skills {
  color: var(--blue);
  transition: color 100ms;
}

.contact {
  color: var(--pink);
  transition: color 100ms;
}

@media only screen and (max-width: 821px) {
}

@media only screen and (max-width: 480px) {
  .panel {
    font-size: 0.7rem;
  }

  .panel.open {
    max-height: unset;
  }
}