.project-container {
  min-height: 85vh;
  min-height: calc(var(--vh, 1vh) * 85);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  scroll-snap-align: start;
  margin: 1rem 0;
}

.portfolio-header {
  font-size: 2.5rem;
  margin: 1rem 0;
  color: var(--green);
  text-align: center;
}

.project-card {
  box-sizing: border-box;
  background-color: rgba(245, 245, 245, 0.1);
  padding: 0 2rem;
  min-height: 65vh;
  width: 70ch;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30% 70%;
  grid-template-areas:
    'heading heading'
    'image text';
}

.project-title {
  grid-area: heading;
  font-size: 2.5rem;
  margin: 0;
  display: flex;
  align-items: center;
}

.project-container > .preview-container {
  grid-area: image;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.text-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  grid-area: text;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}

.prev-project-button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  grid-area: prev;
  justify-self: center;
  align-self: center;
  transition: all 200ms ease-in-out;
}

.next-project-button {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  grid-area: next;
  justify-self: center;
  align-self: center;
  transition: all 200ms ease-in-out;
}

.prev-project-button:hover,
.next-project-button:hover {
  color: var(--green);
  transform: scale(1.1);
}

.repo-link {
  margin-left: 1rem;
  margin-right: auto;
  text-decoration: none;

  color: var(--white);
}

.repo-link:hover {
  color: var(--green);
}

@media only screen and (max-width: 821px) {
  .project-container > * {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .project-container {
    width: 100%;
    max-height: 85vh;
  }

  .portfolio-header {
    font-size: 1.2rem;
  }

  .project-card {
    width: 95vw;
    padding: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'heading'
      'image'
      'text';
    overflow-x: hidden;
    font-size: 1rem;
  }

  .project-title {
    font-size: 1.5rem;
    margin: 0 auto 1rem auto;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}