.contact-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 70%;
  gap: 0.5rem;
}

.contact-form-container label {
  font-size: 1rem;
}

.contact-form-container input {
  height: 2rem;
  background: rgba(220, 223, 228, 0.1);
  color: var(--white);
  border: none;
  padding: 0 0.5rem;
  transition: background-color 100ms ease-in-out;
  animation: 1s forwards cubic-bezier(0.6, -0.28, 0.735, 0.045) form_fadein;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  outline: none;
  background-color: rgba(198, 120, 221, 0.5);
}

.contact-form-container textarea {
  background: rgba(220, 223, 228, 0.1);
  color: var(--white);
  border: none;
  height: 10vh;
  padding: 0.5rem;
  animation: 1s forwards cubic-bezier(0.6, -0.28, 0.735, 0.045) form_fadein;
}

.contact-form-container button {
  height: 2rem;
  font-weight: bold;
  background-color: var(--white);
  color: var(--black);
  border: none;
  width: 100%;
  transition: background-color 100ms ease-in-out;
  margin-top: 0.5rem;
}

.contact-form-container button:hover {
  background-color: var(--pink);
  color: var(--black);
}

@keyframes form_fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .contact-form-container input,
  .contact-form-container textarea {
    border: solid 1px var(--white);
  }
}