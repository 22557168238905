.portfolio-container::-webkit-scrollbar {
  width: 10px;
  background-color: var(--scrollbar);
}

.portfolio-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px var(--scrollbar-thumb);
}

.portfolio-container {
  width: 100%;
  height: 85vh;
  height: calc(var(--vh, 1vh) * 85);
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar) var(--scrollbar-thumb);
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
}

.portfolio-container > section {
  margin-bottom: 5rem;
}

.projects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 821px) {
  .projects-wrapper {
    gap: 0px;
  }
}

@media only screen and (max-width: 480px) {
}