.skills-container::-webkit-scrollbar {
  width: 10px;
  background-color: var(--scrollbar);
}

.skills-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px var(--scrollbar-thumb);
}

.skills-container {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  min-height: calc(var(--vh, 1vh) * 85);
  width: 70ch;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar) var(--scrollbar-thumb);
}

.skills-header {
  color: var(--blue);
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 0;
}

.skills-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: auto;
  overflow: auto;
}

.skill-item-wrapper {
  width: 6rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  opacity: 0;
}

.skill-icon {
  font-size: 3rem;
}

.skill-item-fadeIn {
  animation: fadeIn 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 821px) {
}

@media only screen and (max-width: 480px) {
  .skills-list {
    padding: 1rem;
    gap: 0.5rem;
  }

  .skill-icon {
    font-size: 2rem;
  }
}