.popup-overlay {
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  color: var(--white);
  font-size: 2rem;
  font-style: italic;
  font-weight: 700;
}